import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #fefefe;
  color: ${props => props.theme.colors.black.base};
  margin: 0 auto 0 0;
  width: 100%;

  font-size: 14px;
  line-height: 1.3em;
  font-weight: thin;
`

export const Header = styled.div`
  width: 100%;
  z-index: 30;
  position: relative;
  background-size: cover;
  background-position: 50% 0;
  transition: background-color 0.3s, opacity 0.3s;
`

export const HeaderWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
`

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  z-index: 11;
  position: relative;
  background-color: #000000;
  color: #ffffff;

  min-height: 21px;
  max-height: 34px;
`

export const TopBarContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
`

export const SectionContainer = styled.div`
  background: ${props => props.background || props.theme.colors.white.base};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};

  > .full_width_section {
    max-width: 100%;
  }
`

export const SectionInnerWrapper = styled.div`
  max-width: ${props => props.theme.breakpoints.rs};
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};
  padding: ${props => (props.padding ? props.padding : "30px 20px")};
  margin: 0px auto;

  .medium_full_width_row {
    max-width: 1080px;
    margin: 0px auto;

    @media (max-width: 850px) {
      flex-direction: column;
    }
  }

  @media (max-width: 550px) {
    padding-bottom: 0px;
    padding-top: 20px;
  }
`

export const SectionHeading = styled.h2`
  font-size: 36px;
  line-height: 1.1;
  color: ${props => props.theme.colors.black.base};
  font-family: "BebasNeueBold";
  font-weight: bold;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* border-bottom-color:${props => props.theme.colors.black.base};
  border-bottom-width:3px;
  border-bottom-style: solid;
  padding-bottom:10px; */
  text-align:center;
  margin-bottom: 0px;
  letter-spacing: .05em;
  text-transform: uppercase;
  
  @media(max-width: 850px) {
    font-size: 28px;
  }
  
  @media(max-width: 550px) {
    margin: 0px;
    line-height: 1;
    font-size: 24px;
    padding: 0px;
    align-items: baseline;
    width: 100%;
    text-align: left;
  }

  &:after {
    content: "";
    height: 4px;
    background: ${props => props.theme.colors.black.base};
    width: 120px;
    margin: 0 auto;
  
    @media(max-width: 550px) {
      margin: 5px 0px 0.4em 0px;
      max-width: 100px;
    }
  }
`

export const SectionMeta = styled.div`
  font-size: 14px;
  line-height: 1.5em;
  color: ${props => props.theme.colors.black.base};
  margin: 30px 0;
  padding: 0 80px;
  text-align: center;
  font-family: "Gotham Book";
  font-weight: 300;

  > div > div:last-of-type {
    @media (max-width: 550px) {
      margin-top: 10px;
      display: inline-block;
    }
  }

  @media (max-width: 550px) {
    padding: 0px;
    text-align: left;
    margin: 10px 0px;
  }

  span {
    @media (max-width: 550px) {
      margin-top: 10px;
      display: inline-block;
    }
  }
`

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};

  @media (max-width: 550px) {
    flex-direction: column;
  }

  .medium_full_width_column {
    @media (max-width: 850px) {
      flex-basis: 100%;
      width: 100%;
      padding: 0px 0px 20px 0px;
    }
  }
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  align-items: ${props => (props.align ? props.align : "center")};
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  padding: 0px 15px 20px;
  flex-basis: 50%;

  @media (max-width: 550px) {
    width: 100%;
    padding: 0px 0px 20px;
  }

  .icon-box {
    display: flex;
    justify-content: center;
  }
  .icon-box-img {
    flex-basis: 6%;
  }
  .icon-box-img img {
    width: auto;
    height: auto;
  }
  .icon-box-text {
    flex-basis: 94%;
    padding-left: 1em;
  }
  .icon-box-text h3 {
    margin-bottom: 5px;
    font-size: 16.1px;
    font-family: "GothamLight";
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

export const SubTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};
  margin-bottom: 10px;

  h4 {
    margin-bottom: 5px;
  }

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

export const DesktopStarBoxWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};

  @media (max-width: 992px) {
    display: none;
  }
`

export const MobileStarBoxWrapper = styled.div`
  display: none;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};

  @media (max-width: 992px) {
    display: flex;
  }
`

export const StickySiderBarSection = styled.div`
  background: ${props => props.background || props.theme.colors.white.base};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};
  padding: 0px 20px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`

export const StickySideBarLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};
  width: calc(100% - 370px);
  max-width: 1080px;
  width: 100%;
  padding: 0px 15px;

  @media (min-width: 992px) {
    width: calc(100% - 270px);
    width: 100%;
  }

  @media (min-width: 1600px) {
    width: calc(75% - 50px);
    width: 100%;
  }

  @media (max-width: 992px) {
    width: 100%;
    padding: 0px;
    border-right: 0px solid #ddd;
  }
`

export const StickySideBarLeftSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};
  width: calc(100% - 370px);
  padding: 0px 15px;
  border-right: 10px solid #ddd;

  @media (min-width: 992px) {
    width: calc(100% - 270px);
  }

  @media (min-width: 1600px) {
    width: calc(75% - 50px);
  }

  @media (max-width: 992px) {
    width: 100%;
    padding: 0px;
    border-right: 0px solid #ddd;
  }
`

export const StickySideBarRight = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};
  max-width: 25%;
  flex-basis: 25%;
  z-index: 1;
  position: sticky;
  top: 100px;

  @media (max-width: 1200px) {
    max-width: 100%;
  }

  @media (max-width: 992px) {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    z-index: auto;
    position: relative;
    top: 0px;
  }
`

export const FullWidthRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};
  max-width: 1080px;

  @media (max-width: 550px) {
    flex-direction: column;
  }

  .ColumnBox8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%;

    @media (max-width: 550px) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .ColumnBox4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%;

    @media (max-width: 550px) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
`

export const ColumnBox = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  align-items: ${props => (props.align ? props.align : "center")};
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  padding: 0px 12px 30px 12px;

  @media (max-width: 550px) {
    width: 100%;
    padding: 0px 0px 20px;
  }

  &:first-of-type .overlay_box {
    right: 0px;
  }

  &:nth-of-type(2) .overlay_box {
    left: 0px;
  }

  .ColumnBox8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%;
  }

  .ColumnBox4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
`

export const ColumnBox8 = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  align-items: ${props => (props.align ? props.align : "center")};
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  padding: 0px 15px 20px;
  max-width: 66.66667%;
  flex-basis: 66.66667%;

  @media (max-width: 550px) {
    width: 100%;
    padding: 0px 0px 20px;
  }
`

export const ColumnBox4 = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  align-items: ${props => (props.align ? props.align : "center")};
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  padding: 0px 15px 20px;
  max-width: 33.33333%;
  flex-basis: 33.33333%;

  @media (max-width: 550px) {
    width: 100%;
    padding: 0px 0px 20px;
  }
`

export const ColumnText = styled.div`
  font-family: "GothamLight";
  font-weight: 300;

  > h2 {
    font-family: "GothamLight";
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 0.05em;
    margin-bottom: 0.5em;
    font-size: 2em;

    @media (max-width: 550px) {
      font-size: 21px;
      line-height: 24px;
      font-weight: bold;
    }

    > span {
      font-size: 0.8em;
      color: #000000;

      @media (max-width: 550px) {
        font-size: 21px;
        line-height: 24px;
      }
    }
  }

  li h3 {
    font-weight: bold;
    color: #262626;
    font-size: 1.5em;
    line-height: 24px;
    font-family: "GothamLight";
  }

  li {
    line-height: 24px;
  }

  li strong {
    font-weight: bold;
    color: #262626;
    font-size: 1.5em;
    line-height: 24px;

    @media (max-width: 550px) {
      font-size: 21px;
    }
  }
  .icon-box-img img {
    width: auto;
    height: auto;
  }
  .icon-box-text {
    flex-basis: 94%;
    padding-left: 1em;
  }
  .icon-box-text h3 {
    margin-bottom: 5px;
    font-family: "GothamLight";
  }
`

export const FullColumnWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  padding: 0px 15px 20px;
  flex-basis: 100%;

  @media (max-width: 550px) {
    padding: 0px;
  }

  > h2 {
    font-size: 19.6px;
    color: #000000;
    text-align: center;
    line-height: 1.2;
    text-transform: uppercase;
    font-family: "GothamLight";
    font-weight: 300;

    @media (max-width: 550px) {
      font-size: 1.2em;
      text-align: left;
    }
  }

  > h2.lifestyle_title {
    @media (max-width: 550px) {
      font-size: 24px;
      text-align: left;
    }
  }

  > h4 {
    font-size: 19.6px;
    color: #000000;
    text-align: center;
    line-height: 1.2;
    text-transform: uppercase;
    font-family: "GothamLight";
    font-weight: 300;

    @media (max-width: 550px) {
      font-size: 1.2em;
      text-align: left;
    }
  }

  > h4.lifestyle_title {
    @media (max-width: 550px) {
      font-size: 24px;
      text-align: left;
    }
  }
`

export const LifestyleColumnWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  align-items: ${props => (props.align ? props.align : "center")};
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  padding: 0px 1px 20px 1px;
  flex-basis: 50%;

  :nth-of-type(1) span {
    color: #16a6a2;
  }
  :nth-of-type(2) span {
    color: #ef705b;
  }
  :nth-of-type(3) span {
    color: #cb1517;
  }
`

export const LifestyleColumn = styled.div`
  text-align: center;

  > img {
    width: 60px;
    margin: 0px auto;
  }
  div {
    color: #262626;
    font-size: 16px;
    line-height: 1.4em;
    text-transform: uppercase;
  }
  h3 {
    color: #262626;
    font-size: 16px;
    line-height: 1.4em;
    text-transform: uppercase;
    font-family: "GothamLight";
    font-weight: 500;
    margin: 0px;
  }
`

export const ProductSectionTitle = styled.div`
  text-align: center;
  position: relative;
  background-size: contain;
  background-image: ${props =>
    props.background
      ? props.background
      : "url(https://static.percko.com/uploads/520e45cb-de51-4906-86d3-1ca7bc84e62c.png)"};
  padding: 0px 20px;

  > .product_section_lyneup_title,
  .product_section_lynefit_title,
  .product_section_lynepro_title {
    position: relative;
  }

  .product_section_lyneup_title h2 > span,
  .product_section_lyneup_title h4 > span {
    color: #16a6a2;
  }

  .product_section_lyneup_title h2 > span {
    @media (max-width: 550px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }
  }

  .product_section_lynefit_title h2 > span,
  .product_section_lynefit_title h4 > span {
    color: #ef705b;
  }

  .product_section_lynefit_title h2 > span {
    @media (max-width: 550px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }
  }

  .product_section_lynepro_title h2 > span,
  .product_section_lynepro_title h4 > span {
    color: #cb1517;
  }

  .product_section_lynepro_title h2 > span {
    @media (max-width: 550px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }
  }

  h2 {
    font-weight: 300;
    color: #000000;
    font-family: "GothamLight";
    margin-top: 1.45rem;
    font-size: 20px;
    letter-spacing: 0.05em;

    @media (max-width: 550px) {
      font-size: 16px;
    }
  }
  h2 > span {
    font-weight: bold;
    font-size: 40px;

    @media (max-width: 550px) {
      font-size: 28px;
    }
  }
  h2 > span > span {
    display: none;

    @media (max-width: 550px) {
      display: inline;
      max-width: 100px;
      margin-left: 20px;
    }

    > img {
      margin: 0px;
    }
  }
  h4 {
    font-size: 17px;
    color: #666;
    text-align: center;
    font-family: "GothamLight";
    letter-spacing: 0;
  }
`

export const TitleOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: -1px;
  right: -1px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: transform 0.3s, opacity 0.6s;
  pointer-events: none;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
`

export const MedicaleLogo = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  right: 20px;
  max-width: 100px;

  @media (max-width: 550px) {
    display: none;
  }

  > img {
    margin: 0px;
  }
`

export const ButtonInline = styled.div`
  display: inline-block;
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 550px) {
    width: 100%;
    flex-direction: column;
  }
`

export const ButtonWrapper = styled(Link)`
  background-color: #262626;
  position: relative;
  display: inline-block;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  -ms-touch-action: none;
  touch-action: none;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  color: #fff;
  text-decoration: none;
  border: 1px solid transparent;
  vertical-align: middle;
  border-radius: 5px;
  margin-top: 0;
  margin-right: 1em;
  text-shadow: none;
  line-height: 2.4em;
  min-height: 2.5em;
  padding: 0 1em;
  max-width: 100%;
  transition: transform 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s,
    opacity 0.3s, color 0.3s;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  margin-bottom: 1em;

  @media (max-width: 550px) {
    width: 100%;
    font-weight: bold;
    font-size: 14px;
  }

  :hover {
    color: #fff;
  }
`

export const ProductTitle = styled.div`
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > h4 {
    color: #000000;
    text-align: center;
    margin-bottom: 0;
    font-size: 36px;
    font-family: "GothamLight";
    font-weight: 300;
  }
  > hr {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    min-width: 120px;
    height: 4px;
    background-color: rgb(93, 185, 188);
  }
`

export const ProductSubTitle = styled.div`
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > h4 {
    text-align: center;
    font-size: 1.2em;
    font-family: "GothamLight";
    font-weight: 300;
    margin: 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "column")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};
  max-width: 1080px;
  width: 100%;
  margin: 0px auto;
`

export const Row = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props => (props.justify ? props.justify : "center")};
  width: 100%;
  margin: 0px auto;
`

export const FaqSectionWrapper = styled.div`
  color: #262626;
  background-color: #fff;
  padding: 0px 30px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  margin: 0px auto;

  @media (max-width: 1600px) {
    padding: 0px 20px 30px;
  }
`

export const FaqWrapper = styled.div`
  color: #262626;
  width: 100%;
  display: flex;
`

export const FaqLeftDiv = styled.div`
  color: #262626;
  width: 100%;
  display: flex;
  flex: 1;
  max-width: 400px;

  @media (max-width: 850px) {
    display: none;
  }
`

export const FaqRightDiv = styled.div`
  color: #262626;
  margin-left: 30px;
  width: 100%;
  display: flex;
  flex: 2;
  flex-direction: column;
  max-width: 1080px;

  @media (max-width: 850px) {
    margin: 0px;
  }
`

export const FaqCategoryListDiv = styled.div`
  color: #262626;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
  width: 100%;
  margin: 0px;
  flex-direction: column;
  flex-wrap: nowrap;
`

export const FaqCategoryBoxWrapper = styled.div`
  color: #262626;
  width: 100%;
  padding: 0px 15px 30px;
  flex-basis: 33.3333%;
  display: flex;
  padding: 0px;
  margin-bottom: 10px;

  @media (max-width: 1400px) {
    flex-basis: 50%;
  }

  @media (max-width: 550px) {
    flex-basis: 100%;
  }
`

export const FaqCategoryBox = styled(Link)`
  color: #262626;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid #eaeaea;
  padding: 15px;
  padding: 0px;
  border: 0px solid #eaeaea;
`

export const FaqCategoryBoxTitle = styled.h3`
  color: #f37f50;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: -0.02em;
  font-family: "GothamLight";
  font-weight: bold;
  margin-bottom: 0.5em;
`

export const FaqPageTitle = styled.h1`
  color: #f37f50;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: -0.02em;
  font-family: "GothamLight";
  font-weight: bold;
  margin-bottom: 0.5em;

  span {
    font-style: italic;
  }
`

export const FaqTextBox = styled.div`
  color: #262626;
  font-size: 16px;
  line-height: 20px;
  font-family: "GothamLight";
  width: 100%;
  letter-spacing: 0;

  p {
    margin-bottom: 1.3em;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  ul {
    list-style: none;
    margin-left: 10px;
  }

  a {
    text-decoration: underline;
  }
`
